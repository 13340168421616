import { createEnv } from '@t3-oss/env-nextjs';
import { z } from 'zod';

/**
 * Custom Zod JSON schema
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const json = <TData extends object = any>(options: { optional?: boolean } = {}) =>
  z.string().transform((content, ctx) => {
    if (!content && options.optional) return null;
    try {
      return JSON.parse(content) as TData;
    } catch (error) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Invalid JSON',
      });
      return z.never;
    }
  });

/**
 * Custom Zod Boolean schema that accepts 'true', 'false', '1' and '0' as valid values
 */
const boolean = () =>
  z
    .union([z.literal('true'), z.literal('false'), z.literal('1'), z.literal('0')])
    .optional()
    .transform((value) => value === 'true' || value === '1');

export const env = createEnv({
  server: {
    FIREBASE_ADMIN_CREDENTIAL: json({ optional: true }),
    FIREBASE_PRIVATE_KEY: z.string().optional(),
    ELEVENLABS_API_KEY: z.string().min(1),
    OPENAI_API_KEY: z.string().min(1),
  },

  client: {
    NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN: z.string().optional(),
    NEXT_PUBLIC_ROLLBAR_SERVER_TOKEN: z.string().optional(),
    NEXT_PUBLIC_TIME_WINDOW_MIN: z.number().optional(),
    NEXT_PUBLIC_ROLLBAR_ENV: z.string().optional(),
    NEXT_PUBLIC_SERVER_URL: z.string().url(),
    NEXT_PUBLIC_FIREBASE_CONFIG: json(),
    NEXT_PUBLIC_FIREBASE_MESSAGING_VAPID_KEY: z.string().min(1),
    NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA: z.string().optional(),
    NEXT_PUBLIC_URL: z.string().optional(),
    NEXT_PUBLIC_GA_TRACKING_ID: z.string().optional(),
    NEXT_PUBLIC_GOOGLE_ANALYTICS: z.string().optional(),
    NEXT_PUBLIC_FACEBOOK_PIXEL_ID: z.string().optional(),
    NEXT_PUBLIC_SAMPLE_ORG_ID: z.string().optional(),
    NEXT_PUBLIC_FORCE_SUBDOMAIN: z.string().optional(),
    NEXT_PUBLIC_ENABLE_SUBSCRIPTION: boolean(),
    NEXT_PUBLIC_SUPER_ORG_ID: z.string().optional(),
    NEXT_PUBLIC_AGENTO_ASSISTANT_ID: z.string().optional(),
  },

  runtimeEnv: {
    FIREBASE_ADMIN_CREDENTIAL: process.env.FIREBASE_ADMIN_CREDENTIAL,
    FIREBASE_PRIVATE_KEY: process.env.FIREBASE_PRIVATE_KEY,
    ELEVENLABS_API_KEY: process.env.ELEVENLABS_API_KEY,
    OPENAI_API_KEY: process.env.OPENAI_API_KEY,
    NEXT_PUBLIC_SUPER_ORG_ID: process.env.NEXT_PUBLIC_SUPER_ORG_ID,
    NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN: process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN,
    NEXT_PUBLIC_ROLLBAR_SERVER_TOKEN: process.env.NEXT_PUBLIC_ROLLBAR_SERVER_TOKEN,
    NEXT_PUBLIC_TIME_WINDOW_MIN: +(process.env.NEXT_PUBLIC_TIME_WINDOW_MIN || 0),
    NEXT_PUBLIC_ROLLBAR_ENV: process.env.NEXT_PUBLIC_ROLLBAR_ENV,
    NEXT_PUBLIC_SERVER_URL: process.env.NEXT_PUBLIC_SERVER_URL,
    NEXT_PUBLIC_FIREBASE_CONFIG: process.env.NEXT_PUBLIC_FIREBASE_CONFIG,
    NEXT_PUBLIC_FIREBASE_MESSAGING_VAPID_KEY: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_VAPID_KEY,
    NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
    NEXT_PUBLIC_URL: process.env.NEXT_PUBLIC_URL,
    NEXT_PUBLIC_GA_TRACKING_ID: process.env.NEXT_PUBLIC_GA_TRACKING_ID,
    NEXT_PUBLIC_GOOGLE_ANALYTICS: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS,
    NEXT_PUBLIC_FACEBOOK_PIXEL_ID: process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID,
    NEXT_PUBLIC_SAMPLE_ORG_ID: process.env.NEXT_PUBLIC_SAMPLE_ORG_ID,
    NEXT_PUBLIC_FORCE_SUBDOMAIN: process.env.NEXT_PUBLIC_FORCE_SUBDOMAIN,
    NEXT_PUBLIC_ENABLE_SUBSCRIPTION: process.env.NEXT_PUBLIC_ENABLE_SUBSCRIPTION,
    NEXT_PUBLIC_AGENTO_ASSISTANT_ID: process.env.NEXT_PUBLIC_AGENTO_ASSISTANT_ID,
  },
});

export type Environment = typeof env;
