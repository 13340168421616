import { Entity } from '@/stores/slices/ApiSlice';
import useGet, { GetOptions } from './useGet';
import usePost, { PostOptions } from './usePost';
import { useStore } from '@/stores/store';
import { useState } from 'react';
import usePut, { PutOptions } from './usePut';
import useDelete, { DeleteOptions } from './useDelete';

export type Keys =
  | 'users'
  | 'assistants'
  | 'auth'
  | 'createPassword'
  | 'me'
  | 'organizations'
  | 'knowledge'
  | 'missions'
  | 'notificationToken'
  | 'activities'
  | 'knowledgeSuggestions'
  | 'tags'
  | 'thread'
  | 'outboundThreads'
  | 'contactFile'
  | 'channels'
  | 'testNotification'
  | 'suggestions'
  | 'integrations'
  | 'preSubscriptions'
  | 'subscriptions'
  | 'threadUser'
  | 'affiliate';

export const ApiConstants = {
  users: {
    url: '/api/user',
    entity: 'users',
  },
  assistants: {
    url: '/api/assistant',
    entity: 'assistants',
  },
  auth: {
    url: '/api/auth/login',
  },
  createPassword: {
    url: '/api/auth/createPassword',
  },
  me: {
    url: '/api/auth/me',
  },
  organizations: {
    url: '/api/organization',
    entity: 'organizations',
  },
  knowledge: {
    url: '/api/knowledge',
    entity: 'knowledge',
  },
  missions: {
    url: '/api/mission',
    entity: 'missions',
  },
  notificationToken: {
    url: '/api/auth/me/notificationToken',
  },
  activities: {
    url: '/api/activity',
    entity: 'activities',
  },
  knowledgeSuggestions: {
    url: '/api/knowledgeSuggestion',
    entity: 'knowledgeSuggestions',
  },
  outboundThreads: {
    url: '/api/mission',
    entity: 'outboundThreads',
  },
  tags: {
    url: '/api/tag',
    entity: 'tags',
  },
  thread: {
    url: '/api/thread',
    entity: 'thread',
  },
  contactFile: {
    url: '/api/file',
  },
  channels: {
    url: '/api/channel',
  },
  testNotification: {
    url: '/api/system/testNotification',
  },
  integrations: {
    url: '/api/integration',
  },
  suggestions: {
    url: '/api/mission',
  },
  preSubscriptions: {
    url: '/api/preSubscription',
  },
  subscriptions: {
    url: '/api/subscription',
  },
  threadUser: {
    url: '/api/threadUser',
  },
  affiliate: {
    url: '/api/affiliate',
    entity: 'affiliates',
  },
} as {
  [key in Keys]: {
    url: string;
    entity?: Entity;
  };
};

export type ErrorType = { status?: number; message: string; data?: unknown };

function useApi<T>(key: Keys) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ErrorType>();

  const { get } = useGet(key, { autoFetch: false } as unknown as GetOptions<T>);
  const { post } = usePost<T>(key);
  const { put } = usePut<T>(key);
  const { remove } = useDelete(key);

  if (!ApiConstants[key]) throw new Error(`Key ${key} not found in ApiConstants`);

  const entity = ApiConstants[key].entity;
  const data = useStore((store) => (entity ? store[entity] : null)) as T[] | null;

  return {
    data,
    loading,
    error,
    useGet: (options: Omit<GetOptions<T>, 'autoFetch'>) =>
      useGet<T>(key, { ...options, autoFetch: true }, setLoading, setError),
    get: function <K>(options: Omit<GetOptions<K>, 'autoFetch'>) {
      return get<K>(options, setLoading, setError);
    },
    post: function <K>(data: T, options: PostOptions<K>) {
      return post<K>(data, options, setLoading, setError);
    },
    put: function <K>(data: T, options: PutOptions<K>, id?: string) {
      return put<K>(data, options, setLoading, setError, id);
    },
    remove: (id: string, options: DeleteOptions) => remove(id, options, setLoading, setError),
    setLoading,
    setError,
  };
}

export default useApi;
