import { Organization } from '@/typings/backend';
import { StoreInterface } from '@/stores/store';
import { StateCreator } from 'zustand';
import { ORGANIZATION, ORGANIZATIONS } from '@/utils/storageKeys';
import { setCookie } from 'cookies-next';

type PartialOrganization = {
  id: Organization['id'];
  name: Organization['name'];
  active: Organization['active'];
  createdAt: Organization['createdAt'];
  slug: Organization['slug'];
};

export type AppDataSlice = {
  // organization: Organization | null;
  selectedOrganization: PartialOrganization | null;
  organizationData: PartialOrganization[] | null;
  emitNewMessageSound: boolean;
  setEmitNewMessageSound: (value: boolean) => void;
  // setOrganization: (org: Organization) => void;
  setSelectedOrganization: (value: string | PartialOrganization) => void;
  setOrganizationData: (value: Organization[]) => void;
};

export const createAppDataSlice: StateCreator<
  StoreInterface,
  [],
  [],
  AppDataSlice /* Current slice type */
> = (set, get) => ({
  // organization: null,
  selectedOrganization: null,
  organizationData: [],
  emitNewMessageSound: false,
  setEmitNewMessageSound: (value) => {
    set({ emitNewMessageSound: value });
  },
  // setOrganization: (organization) => {
  //   set({ organization });
  // },
  setSelectedOrganization: (value) => {
    let org: PartialOrganization | null = value as PartialOrganization;

    if (typeof value === 'string') {
      const orgData = get().organizationData;
      org = orgData?.find((org) => org.id === value) || null;
    }

    const maxAge = 1000 * 60 * 60 * 24 * 365; // 1 year for now
    setCookie(ORGANIZATION, JSON.stringify(org), { maxAge });
    set({ selectedOrganization: org });
  },
  setOrganizationData: (data) => {
    const orgs = data.map(({ id, name, active, createdAt, slug }) => ({
      id,
      active,
      name,
      createdAt,
      slug,
    }));
    // One year
    const maxAge = 1000 * 60 * 60 * 24 * 365; // 1 year for now
    setCookie(
      ORGANIZATIONS,
      JSON.stringify(orgs.map(({ id, name, active }) => ({ id, name, active }))),
      { maxAge },
    );

    set({ organizationData: orgs });

    const org = get().selectedOrganization;

    if (org?.id) {
      const orgData = orgs.find((org) => org.id === org.id);
      if (orgData) {
        set({ selectedOrganization: org });
      }
    }
  },
});
