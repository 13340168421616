import axiosLib from 'axios';
import rollbarServer, { createRollbarInstance } from './rollbar';
import { getCookie } from 'cookies-next';
import { ORGANIZATION, USER_TOKEN } from './storageKeys';
import { shouldSkipLoggingForAuthError } from './error';
import { env } from '@/env';

const axios = axiosLib.create({
  baseURL: env.NEXT_PUBLIC_SERVER_URL,
});

export const setAxiosAuthentication = (authenticationToken?: string | null) => {
  axios.defaults.headers.common['Authorization'] = authenticationToken
    ? `Bearer ${authenticationToken}`
    : ''; // Clean header
  axios.defaults.headers.common['Organization-Id'] = '668e9aacb62d6e240847377f'; // Harding-coding for now
};

export const setAxiosOrganizationId = (organizationId?: string | null) => {
  axios.defaults.headers.common['Organization-Id'] = organizationId;
};

// Error handling
axios.interceptors.response.use(undefined, (error) => {
  if (shouldSkipLoggingForAuthError(error)) {
    // Auth error that should not log to rollbar
    return Promise.reject(error);
  }
  if (typeof window === 'undefined') {
    // Server error
    rollbarServer.error('Error on axios response - server', { error });
  } else {
    // Client error
    const rollbarClient = createRollbarInstance(true);
    rollbarClient?.error('Error on axios response - client', { error });
  }
  return Promise.reject(error);
});

// Authentication
axios.interceptors.request.use(
  (config) => {
    if (!config.headers?.['Authorization']) {
      // Token is not defined, trying to redefine it
      const token = getCookie(USER_TOKEN);
      // Locally defining token for the ongoing request
      if (config.headers) config.headers['Authorization'] = token ? `Bearer ${token}` : '';
      // Setting default headers for the next requests
      setAxiosAuthentication(token);
    }

    const org = getCookie(ORGANIZATION);
    const orgId = JSON.parse(org || '{}').id as string | undefined;
    if (
      orgId &&
      (!config.headers?.['Organization-Id'] || config.headers['Organization-Id'] !== orgId)
    ) {
      config.headers['Organization-Id'] = orgId;
      setAxiosOrganizationId(orgId);
    }

    // Do something before request is sent
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  },
);

export default axios;
