'use client';

import { useEffect } from 'react';
import { type Serwist } from '@serwist/window';
import PWAInstallBanner from './PWAInstallBanner';

declare global {
  interface Window {
    serwist: Serwist;
  }
}

export default function RegisterPWA() {
  useEffect(() => {
    const register = async () => {
      if ('serviceWorker' in navigator && window.serwist !== undefined) {
        await window.serwist.register();
        await window.serwist.update();
      }
    };

    register();
  }, []);

  return <PWAInstallBanner />;
}
