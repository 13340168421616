'use client';
import React, { PropsWithChildren } from 'react';
import { AppProgressBar as ProgressBar } from 'next-nprogress-bar';
import { useMantineTheme } from '@mantine/core';
import { useStore } from '@/stores/store';

const ProgressProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const theme = useMantineTheme();
  const hasUnsavedChanges = useStore((store) => store.hasUnsavedChanges);

  if (hasUnsavedChanges) {
    return <>{children}</>;
  }

  return (
    <>
      {children}
      <ProgressBar
        height="2px"
        color={theme.colors.primary[5]}
        options={{ showSpinner: false }}
        shallowRouting
      />
    </>
  );
};

export default ProgressProvider;
