'use client';

import { useState, useEffect } from 'react';
import {
  ActionIcon,
  Button,
  Group,
  Popover,
  Stack,
  Text,
  Transition,
  useMantineTheme,
} from '@mantine/core';
import { IconSquarePlus, IconX } from '@tabler/icons-react';
import Image from 'next/image';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { isBrowser, isMobile as isMobileOS, isPWA } from '@/utils/platform';
import { MdOutlineIosShare } from '@react-icons/all-files/md/MdOutlineIosShare';
import { useStore } from '@/stores/store';

const BANNER_DISMISS_KEY = 'lastDateDismissedPWAInstallBanner';

const PWAInstallBanner = () => {
  const user = useStore((state) => state.user);
  const [url, setUrl] = useState('');
  const [showBanner, setShowBanner] = useState(false);
  const [showIOSBanner, setShowIOSBanner] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null); // eslint-disable-line

  useEffect(() => {
    const url = window.location.origin.split('://')[1];
    setUrl(url);

    const checkLastTimeDismissed = () => {
      const lastDateDismissed = localStorage.getItem(BANNER_DISMISS_KEY);

      if (lastDateDismissed) {
        const lastDate = new Date(lastDateDismissed);
        const now = new Date();
        const diff = now.getTime() - lastDate.getTime();
        // less than 30 days, do not show banner again
        return diff >= 30 * 24 * 60 * 60 * 1000;
      }
      return true;
    };

    const showBanners = checkLastTimeDismissed();

    if (!showBanners) return;

    const showIOSBanner =
      !isPWA() && isMobileOS.iOS() && (isBrowser.Safari() || isBrowser.Chrome());

    if (showIOSBanner) {
      const timer = setTimeout(() => {
        setShowIOSBanner(true);
      }, 2000);
      return () => clearTimeout(timer);
    }

    const handler = (e: Event) => {
      const showBanners = checkLastTimeDismissed();
      if (showBanners) {
        e.preventDefault();
        setDeferredPrompt(e);
        setShowBanner(true);
      }
    };

    window.addEventListener('beforeinstallprompt', handler);

    return () => {
      window.removeEventListener('beforeinstallprompt', handler);
    };
  }, []);

  const handleInstall = async () => {
    if (showIOSBanner) {
      open();
      handleClose();
      return;
    }
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === 'accepted') {
        setShowBanner(false);
        localStorage.removeItem(BANNER_DISMISS_KEY);
      }
      setDeferredPrompt(null);
    }
  };

  const handleClose = () => {
    setShowBanner(false);
    setShowIOSBanner(false);
    // Save last date dismissed
    localStorage.setItem(BANNER_DISMISS_KEY, new Date().toISOString());
  };

  const theme = useMantineTheme();
  const isMobile = useMediaQuery('(max-width: 768px)');

  const [popoverOpened, { close, open }] = useDisclosure();
  const isEnglish = showIOSBanner ? navigator.language.indexOf('en') > -1 : false;

  if (!user) {
    return null;
  }

  return (
    <>
      {/* iOS */}
      <Popover
        width={300}
        opened={popoverOpened}
        position={isBrowser.Safari() ? 'top' : 'bottom-end'}
        withArrow
        arrowSize={22}
        arrowOffset={isBrowser.Chrome() ? 16 : 0}
        shadow="lg"
        onClose={close}
      >
        <Popover.Target>
          <div
            className={`fixed w-1 h-1 ${
              isBrowser.Safari() ? 'bottom-0 right-1/2 ' : 'top-0 right-1'
            }`}
          />
        </Popover.Target>
        <Popover.Dropdown bg={theme.colors.primary[0]}>
          <Text ta="center">
            Pressione o botão{' '}
            <MdOutlineIosShare
              color="var(--mantine-color-primary-6)"
              size={22}
              className="inline mt-[-8px]"
            />{' '}
            do seu navegador e aperte em{' '}
            <b className="inline-flex flex-nowrap items-center gap-1">
              {isEnglish ? 'Add to Home Screen' : 'Adicionar à tela de início'}{' '}
              <IconSquarePlus
                color="var(--mantine-color-primary-6)"
                size={22}
                className="inline mt-[-8px]"
              />
            </b>
          </Text>
        </Popover.Dropdown>
      </Popover>
      {/* Desktop / Mobile Android */}
      <Transition
        mounted={showBanner || showIOSBanner}
        transition={isMobile ? 'fade-up' : 'fade-left'}
        enterDelay={5000}
        duration={400}
        timingFunction="ease"
      >
        {(styles) => (
          <Stack
            style={styles}
            p="md"
            className="fixed bottom-0 left-0 right-0 md:bottom-2 md:right-2 md:left-[unset] bg-darkAccent-900 md:rounded-lg shadow-lg md:w-[500px]"
          >
            <Group>
              <Image src="/icon.png" alt="App Icon" width={48} height={48} />
              <Text c="white" className="flex-1" size="lg" fw="bold">
                Instalar o Agento
                <br />
                <Text span size="md" fw="normal">
                  {url}
                </Text>
              </Text>
              <ActionIcon onClick={handleClose} variant="subtle" color="white">
                <IconX />
              </ActionIcon>
            </Group>
            <Group>
              <Text c="white">Adicione o Agento à sua tela inicial para acessar rapidamente.</Text>
              <Button ml="auto" onClick={handleInstall} color="blue">
                Adicionar á tela inicial
              </Button>
            </Group>
          </Stack>
        )}
      </Transition>
    </>
  );
};

export default PWAInstallBanner;
